<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/home">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>
          <span v-if="locale === 'en'">How to earn</span>
          <span v-if="locale === 'pb'">Como ganhar</span>
        </li>
      </ul>
    </div>
    <div class="main main_page bg-fff">
      <div class="about_one">
        <div class="one">
          <div class="title">
            <span v-if="locale === 'en'">How to earn</span>
            <span v-if="locale === 'pb'">Como ganhar</span>
          </div>
          <div class="text" v-if="locale === 'en'">
            1. Every day you only need to spend 10 minutes to open our App Super
            Shop, go to the task list and select the task you want to do,
            complete it and get the corresponding task commission.
            <br /><br />
            <img src="@/Content/images/guide/stepEarn1.png" />
            <br /><br />
            <br /><br />
            <img src="@/Content/images/guide/stepEarn2.png" />
            <br /><br />
            2. You can also deposit your balance in our trust and we will hold
            the funds for you to manage your money, and we guarantee you the
            safety of your funds and stable profits in the name of God.
            <br /><br />
            <img src="@/Content/images/guide/stepEarn3.png" />
            <br /><br />
            <img src="@/Content/images/guide/stepEarn4.png" />
            <br /><br />
          </div>

          <div class="text" v-if="locale === 'pb'">
            <p>
              • Devido à grande quantidade de informações de recarga, verifique cuidadosamente o número da conta desta plataforma antes de recarregar. A plataforma altera as contas de tempos em tempos. Em caso de dúvidas, clique na plataforma para consulta online de atendimento ao cliente.
            </p>
            <br />
            <p>
              • Até 20 pedidos por conta por dia. Por exemplo: o valor da conta do membro é de R$ 3.000, o valor do envio único é de R$3.000 e a comissão é de R$216 (Nota: o valor de cada pedido é diferente) Nossos pedidos são divididos em 5 níveis, cada nível tem um principal diferente; a renda também é diferente! detalhes da seguinte forma:
            </p>
            <p>
              1. Experimente o mercado: insira o principal R$50, a taxa de comissão é de 6.2%
            </p>
            <p>
              2. Mercado Primário: Insira o principal R$200, a taxa de comissão é de 6,4%
            </p>
            <p>
              3. Mercado médio: Insira o principal R$500, a taxa de comissão é de 6.8%
            </p>
            <p>
              4. Mercado Premium: insira o principal R$1.500, a taxa de comissão é de 7%
            </p>
            <p>
              5. supermercado: Insira o principal R$3.000, a taxa de comissão é de 7.2%
            </p>
            <br />
            <p>
              • Lembrete: Para evitar lavagem de dinheiro maliciosa ou uma série de más condutas, as contas dos membros devem concluir pelo menos 20 pedidos de compra. Se você não cumprir o valor do pedido correspondente, não poderá iniciar uma retirada. As contas de membros podem sacar integralmente após completar 20 pedidos. Depois que a análise de retirada for bem-sucedida, o banco prevalecerá! As retiradas chegarão em até 48 horas.
            </p>
            <br />
            <p>
              • Importante: Todos os cartões bancários em nome de cada membro só podem ser vinculados a uma conta de membro. É proibido fazer login em várias contas com o mesmo IP para comissão.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
